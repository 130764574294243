import { init } from "@jill64/sentry-sveltekit-cloudflare/client";

const onError = init(
  "https://385e218a0a24284178ac6ed359d92e40@o4506239015124992.ingest.sentry.io/4506254481752064",
  {
    sentryOptions: {
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      environment: process.env.NODE_ENV,
    },
  },
);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = onError();
